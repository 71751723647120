
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&family=Mate+SC&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')

// Colors
$premium: #9DCE2A
$dark: #090e14
$white: #ffffff
$medium: #5c5c5c
$lite: #83c3fc
$blue: #55BFEA
$marine: #57B1C2
$blue-lite: #DAEAFE
$grey-lite: #CED0D6
$lite-blue: #D3EDFB
$bg-lite: #F7F8FC
$intro: #ffffff
$theme: #51A2E5
$logo: #9DCE2A

$shadow-4: 0 4px 4px rgba(0,0,0,.15)
$shadow-10: 0 2px 10px rgba(0,0,0,.02)

// Fonts

$corm: 'Merriweather Sans', sans-serif
$play: 'Merriweather Sans', sans-serif
$cin: 'Merriweather Sans', sans-serif
$mont: 'Merriweather Sans', sans-serif
$mer: 'Merriweather Sans', sans-serif
$fira: 'Fira Sans', sans-serif
$rale: 'Merriweather Sans', sans-serif
$open: 'Open Sans', sans-serif

$basic: $open
$serif: $mer

// Placeholder
=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content

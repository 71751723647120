.btn
  display: inline-flex
  justify-content: center
  align-items: center
  width: auto
  height: 44px
  padding: 2px 24px 0
  color: $white
  font-size: .85em
  font-weight: 700
  letter-spacing: .05em
  text-transform: uppercase
  border-radius: 4px
  transition: all ease-out .3s
  background: linear-gradient(360deg, #C5DD4C -8.2%, #9DCE2A 51.43%, #BFDB47 109.84%)
  +v-sm
    zoom: .85
  &:hover
    background-color: $dark
  &--premium
    color: $white
    background: linear-gradient(360deg, #55BFEA -8.2%, #51A2E6 51.43%, #4FBCE8 109.84%)
  &--big
    padding: 0 60px
    height: 60px
    font-size: 1em

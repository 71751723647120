// Intro

.intro
  position: relative
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: flex-start
  width: 100%
  height: auto
  padding: 25vh 2% 30vh
  background: #f3f8f9 url('../../assets/img/bgs/ww.svg')
  background-size: 100%
  overflow: visible
  +v-xs
    padding-top: 20vh
  &__title
    width: 100%
    margin: 0
    padding: 10vh 0 0
    color: $theme
    font-size: 2.65em
    font-family: $fira
    font-weight: 700
    line-height: 1.15
    letter-spacing: .015em
    +v-md
      font-size: 4em
    +v-sm
      margin-top: 20px
      font-size: 2.5em
      line-height: 1.05
    +v-xs
      width: 80%
      margin: 20px auto
      font-size: 1.95em
      line-height: 1.25
  &__subtitle
    width: 100%
    padding: 0
    color: $premium
    font-size: 1.45em
    font-weight: 700
    +v-sm
      font-size: 1.2em
      line-height: 1.25
    +v-xs
      width: 80%
      margin: 20px auto
      line-height: 1.5
  &__text
    width: 100%
    margin: 20px auto
    padding: 0
    color: $medium
    font-size: 1.15em
    font-weight: 500
    line-height: 1.5
    +v-sm
      width: 76%
      font-size: .9em
      margin: 10px auto
//   &__smile
//     display: inline-block
//     position: relative
//     top: 2px
//     left: 4px
//     font-style: normal
//     animation: Smile infinite 2s
//     animation: Smile infinite 2s

// @keyframes Smile
//   from
//     transform: rotate(-360deg)
//   to
//     transform: rotate(0)

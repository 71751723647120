// Title

.title
  position: relative
  width: 60%
  margin-left: auto
  margin-right: auto
  padding-bottom: 20px
  color: $premium
  font-family: $serif
  font-size: 2em
  font-weight: 800
  line-height: 1.5
  text-align: center
  text-transform: uppercase
  &.marine
    color: $marine
  &.dark
    color: $medium
  &.lite
    color: $white
  &.theme
    color: $theme
  &.premium
    color: $premium
  &.blue
    color: $blue
  &.case
    font-size: 2.15em
  &__invert
    color: $white
.subtitle
  padding: 1vh 0 2vh
  color: $blue
  font-family: $basic
  font-size: 1.15em
  font-weight: 600
  &.marine
    color: $marine
  &.medium
    color: lighten($medium, 5%)
  &.dark
    color: $medium
  &.lite
    color: $white
  &.theme
    color: $theme
  &.premium
    color: $premium
  &.blue
    color: $blue
  &.box
    margin: -8px 0 0 -8px
    padding-top: 10px
    font-size: 1.5em
.text
  margin-bottom: 4vh
  padding: 2vh 0
  color: $dark
  font-family: $basic
  font-size: 1.05em
  font-weight: 600
  line-height: 1.75
  &.icon
    font-size: 1em
    line-height: 1.5
    margin: 0
    padding: 0
  &.marine
    color: $marine
  &.medium
    color: lighten($medium, 5%)
  &.dark
    color: $medium
  &.lite
    color: $white
  &.theme
    color: $theme
  &.premium
    color: $premium
  &.blue
    color: $blue
    &.medium
      color: lighten($medium, 5%)

.bg-marine
  background-color: $marine
.bg-medium
  background-color: $medium
.bg-dark
  background-color: $dark
.bg-lite
  background-color: $white
.bg-theme
  background-color: $theme
.bg-premium
  background-color: $premium
.bg-blue
  background-color: $blue
.bg-white
  background-color: $white
.bg-lite
  background-color: $bg-lite

.br-marine
  border-color: $marine
.br-medium
  border-color: $medium
.br-dark
  border-color: $dark
.br-lite
  border-color: $white
.br-theme
  border-color: $theme
.br-premium
  border-color: $premium
.br-blue
  border-color: $blue
.br-white
  border-color: $white
.br-lite
  border-color: $lite

.br-one
  border-width: 1px
.br-two
  border-width: 2px

.c-marine
  color: $marine
.c-medium
  color: $medium
.c-dark
  color: $dark
.c-lite
  color: $white
.c-theme
  color: $theme
.c-premium
  color: $premium
.c-blue
  color: $blue
.c-white
  color: $white
.c-lite
  color: $lite
